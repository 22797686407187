﻿<template>
    <aunoa-nav-item
        v-if="isTenancyVisible"
        :icon="['flag-icon', 'shadow-sm', tenant?.countryFlag()]"
        :text="tenant?.id"
        drop-menu-class="dropdown-menu-end p-0"
        dropdown-tag="div"
        @dropdown-shown="onShown"
    >
        <template v-if="isSearchVisible">
            <form autocomplete="off" style="padding-bottom: 1px" @submit.prevent="onSubmit">
                <input ref="inputElement" v-bind="inputAttrs" v-model="filter"/>
            </form>
            <hr class="dropdown-divider my-0">
        </template>
        <div ref="element" v-bind="attrs">
            <button v-for="t in tenants1" v-bind="getDropdownItemAttrs(t.value, tenant.oid===t.value)" @click="onSelect(t.value)">
                <i class="flag-icon shadow-sm me-2" :class="t.country"/>
                <span v-text="t.text"/>
            </button>
            <hr class="dropdown-divider my-1" v-if="tenants1.length>0 && tenants2.length>0">
            <button v-for="t in tenants2" v-bind="getDropdownItemAttrs(t.value, tenant.oid===t.value)" @click="onSelect(t.value)">
                <i class="flag-icon shadow-sm me-2" :class="t.country"/>
                <span v-text="t.text"/>
            </button>
        </div>
    </aunoa-nav-item>
</template>

<script lang="ts">

import type {LookupOption} from "bootstrap-aunoa";
import type {ScopeItem} from "@tnt/mate-abstractions";

import {AunoaNavItem, AunoaDropdownDivider, useDropdownMenu, useLocalStorage, useTypeahead, useAunoaI18n} from "bootstrap-aunoa";
import {defineComponent, computed, ref} from "vue";
import {useTenancy, useScope} from "@tnt/mate-api";
import {useAuth} from "../utils/useAuth";

const asLookupOption = (tenant: ScopeItem): LookupOption<number> => ({
    value: <number>tenant.oid,
    text: tenant.id,
    country: tenant.countryFlag()
})

export default defineComponent({
    name: "SelectPrimaryTenantNavItem",
    components: {AunoaNavItem, AunoaDropdownDivider},
    setup() {

        const filter = ref("");

        const {user} = useAuth();
        const {t} = useAunoaI18n();
        const {getTenant, tenants} = useScope();
        const inputElement = ref<HTMLInputElement>();
        const tenantCount = computed(() => tenants.value.length);

        const {element, attrs, getDropdownItemAttrs, toValue: scrollToValue} = useDropdownMenu();
        const {primaryTenantOid, rootTenantOid, isTenancyVisible} = useTenancy();
        const tenant = computed(() => getTenant(primaryTenantOid.value || rootTenantOid.value));
        const tenantOids = useLocalStorage(user.value).items("most-recently-used").subscribeObject<number[]>("primary-tenants", []);

        const dummyValue = ref(0);
        const _tenants1 = computed(() => tenantCount.value > 7
            ? tenantOids.value?.map(getTenant).map(asLookupOption) || []
            : []);
        const _tenants2 = computed(() => tenantCount.value > 7
            ? tenants.value.filter(t => !tenantOids.value?.includes(<number>t.oid)).map(asLookupOption)
            : tenants.value.map(asLookupOption));

        const isSearchVisible = computed(() => tenants.value.length > 8);
        const {filteredOptions: tenants1} = useTypeahead(dummyValue, filter, _tenants1, {passWithoutFilter: true});
        const {filteredOptions: tenants2} = useTypeahead(dummyValue, filter, _tenants2, {passWithoutFilter: true});

        const inputAttrs = computed(() => ({
            type: "search",
            class: ["form-control", "form-control-sm"],
            style: {"min-width": "25ch"},
            placeholder: t("Mate.SearchOrganization")
        }));

        const onShown = () => {
            filter.value = "";
            scrollToValue(primaryTenantOid.value);
            if (isSearchVisible.value) {
                setTimeout(() => inputElement.value?.focus(), 100);
            }
        }

        const onSelect = (tenantOid: number) => {
            primaryTenantOid.value = tenantOid;
            tenantOids.value = [tenantOid, ...(tenantOids.value || []).filter(oid => oid !== tenantOid).filter((_, i) => i + 1 < 3)];
        }

        const onSubmit = () => {
            //console.log("onSubmit");
        }

        return {
            filter,
            tenant,
            tenants1,
            tenants2,
            inputAttrs,
            inputElement,
            isSearchVisible,
            isTenancyVisible,
            primaryTenantOid,
            attrs,
            getDropdownItemAttrs,
            element,
            onSubmit,
            onSelect,
            onShown
        }
    }

});

</script>
