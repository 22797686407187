﻿// noinspection ES6PreferShortImport

import type {TextCasing} from "../../utils/textCasing";
import type {ComponentObjectPropsOptions, InjectionKey, PropType, Ref, ComputedRef} from "vue";

import {isArray, isBoolean, isDefined, isFunction, isNumber, isObject, isPromise, isString, isUndefinedOrNull} from "../../utils/inspect";
import {createReplaceRegExp, createSearchOption, createSearchOptionStr, isStrMatching, markStr, SearchOption, SearchOptionStr} from "../../utils/search";
import {computed, inject, provide, ref, toRefs, watch, unref} from "vue";
import {getValueFromPropertyPath} from "../../utils/properties";
import {tryResolveIconAlias} from "../../utils/useIconAlias";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {stringToHslStyles} from "../../utils/chips";
import {toCase} from "../../utils/textCasing";
import {useEntity} from "../useEntity";

// ############################################### types and interfaces

type Nullable<T> = T | null;

export interface LookupValue<TValue = any> {
    value: TValue;
}

export interface LookupDisplay {
    text: Nullable<string>;
    icon?: Nullable<string>;
    country?: Nullable<string>;
    shortcut?: Nullable<string>;
    disabled?: boolean;
    deleted?: boolean;
    colorDeterminationText?: Nullable<string>;
}

export interface LookupOption<TValue = any> extends LookupValue<TValue>, LookupDisplay {
}

export interface LookupOptionsParameter {
    reset: () => void;
}

export type FilterFunction<TValue = any> = (skip: number, take: number, include: string[], exclude: string[]) =>
    LookupOption<TValue>[] | Promise<LookupOption<TValue>[]>;

export interface Lookup<TEntity = any, TValue = any> {
    icon?: string;
    coloredIconBackground?: boolean;
    options?: (entity: TEntity) => LookupOption<TValue>[] | Promise<LookupOption<TValue>[]> | FilterFunction;
    resolve: (value: TValue, entity: TEntity, ...args: any[]) => LookupOption<TValue> | Promise<LookupOption<TValue>> | null | any;
}

export interface LookupFactory<TEntity = any, TValue = any> {
    name: string;
    create: () => Lookup<TEntity, TValue>;
}

export type Lookups = Record<string, Lookup>;

export type LookupFactories = Record<string, LookupFactory>;

export type DataSource<TEntity = any, TValue = any> =
    string
    | Record<any, string | LookupOption<TValue>>
    | Lookup<TEntity, TValue>
    | LookupFactory<TEntity, TValue>
    | LookupOption<TValue>[];

export type Expression = string | ((o: any) => any);

export interface LookupProps<TEntity = any, TValue = any> {
    dataSource: DataSource<TEntity, TValue>;
    valueExpression: Expression;
    textExpression: Expression;
    colorDeterminationTextExpression: Expression;
    icon: string;
    coloredIconBackground: boolean;
    displayCasing: TextCasing;
}

export interface LookupUse<TValue = any> {
    lookup: Ref<Lookup<TValue>>;
    filter: Ref<string>;
    lookupError: Ref<string>;
    defaultIcon: Ref<string>;
    isBusy: Ref<boolean>;

    options: Ref<LookupOption<TValue>[]>;
    optionsDict: Ref<Record<any, LookupOption<TValue>>>;

    optionsHaveIcons: Ref<boolean>;
    optionsHaveFlagIcons: Ref<boolean>;
    optionsHaveColors: Ref<boolean>;
    optionsAreBooleans: Ref<boolean>;

    setFilter: (value: string) => void;
    clearFilter: () => void;
    ensureValue: (value: TValue) => TValue;
    getOption: (value: TValue) => LookupOption<TValue> | Promise<LookupOption<TValue>>;
    //getDisplay: (value: TValue) => LookupDisplay;
    getIcon: (option: LookupOption<TValue>) => any;
    getFlagIcon: (option: LookupOption<TValue>) => any;
    getTranslatedText: (option: LookupOption<TValue>) => string;
    getFilteredText: (option: LookupOption<TValue>) => string;
    getColoredIconStyle: (option: LookupOption<TValue>) => any;
    getTextFromOptionValue: (value: TValue) => string | Promise<string>;
}

// ############################################### helper

export const isLookupFactory = (value: any): value is LookupFactory =>
    value && isString(value.name) && isFunction(value.create);

export const isLookup = (value: any): value is Lookup =>
    value && (isFunction(value.options) || isFunction(value.resolve));

export const isLookupDisplay = (value: any): value is LookupOption =>
    value && isDefined(value.text);

const get1 = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression)
            ? expression(option)
            : option;

const get2 = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression)
            ? expression(option)
            : undefined;

const toArray = (options: any) => Object
    .entries(options)
    .map(([value, text]) => ({value, text})) as any[];

const ensureArray = (value: any): any[] =>
    isArray(value)
        ? value
        : isObject(value)
            ? toArray(value)
            : Array.from(value);

// ############################################### lookup factories

const INJECTION_KEY: InjectionKey<Ref<LookupFactories>> = Symbol();

export const provideLookupFactories = (lookupFactories: Ref<LookupFactories | undefined>) => {

    provide(INJECTION_KEY, lookupFactories);
}

export const useLookupFactories = () => {

    const lookupFactories = inject(INJECTION_KEY, ref<LookupFactories>({}));

    const createLookup = <TEntity = any, TValue = any>(name: string) => {
        const factory = lookupFactories.value[name] as LookupFactory<TEntity, TValue>;
        return factory?.create();
    }

    return {
        createLookup
    }
}


// ############################################### lookup

export const lookupProps: ComponentObjectPropsOptions<LookupProps> = {
    dataSource: {
        type: [String, Object, Array] as PropType<DataSource>,
        default: undefined,
        required: true
    },
    valueExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.value
    },
    textExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.text
    },
    colorDeterminationTextExpression: {
        type: [String, Function] as PropType<Expression>,
        default: undefined
    },
    icon: {
        type: String,
        default: undefined
    },
    coloredIconBackground: {
        type: Boolean,
        default: undefined
    },
    displayCasing: {
        type: String as PropType<TextCasing>,
        default: undefined
    }
}


export const useLookup = <TEntity = any, TValue = any>(props: LookupProps) => {

    const {dataSource} = toRefs(props);

    const filter = ref("");
    const lookupError = ref<string>();
    const lookup = ref<Lookup<TEntity, TValue>>();

    const lookupMax = ref(0);
    const busyCount = ref(0);
    const lookupCount = ref(0);
    //const updateCount = ref(0);

    const isBusy = computed(() => busyCount.value > 0);
    const defaultIcon = computed(() => props.icon || lookup.value?.icon);

    const {entity} = useEntity({});
    const {createLookup} = useLookupFactories();
    const {ensureTextTranslated} = useAunoaI18n();

    const valueOf = (option: LookupOption<TValue>) => get1(option, props.valueExpression) as TValue;
    const textOf = (option: LookupOption<TValue>) => get1(option, props.textExpression) as string;
    const colorDeterminationTextOf = (option: LookupOption<TValue>) => get2(option, props.colorDeterminationTextExpression) as string;

    const optionsHaveColors = computed(() => props.coloredIconBackground
        ? props.coloredIconBackground
        : !!lookup.value?.coloredIconBackground);

    const toDisplayCase = (text: any) => text && props.displayCasing
        ? toCase(text, props.displayCasing)
        : text;

    //const getDisplay = (o: any) => toDisplayCase(get(o, textExpression.value)) as unknown as any;

    const createLookupOption = (item: any | LookupOption<TValue>): LookupOption<TValue> => ({
        value: item.value || valueOf(item),
        text: toDisplayCase(item.text || textOf(item)),
        icon: item.icon,
        country: item.country,
        shortcut: item.shortcut,
        disabled: item.disabled,
        deleted: item.deleted,
        colorDeterminationText: (!item.colorDeterminationText && optionsHaveColors.value)
            ? colorDeterminationTextOf(item)
            : item.colorDeterminationText
    })

    watch(dataSource, value => {
        lookupError.value = undefined;

        if (isString(value)) {
            lookup.value = createLookup(value);
            if (!lookup.value) {
                lookupError.value = `Named Lookup '${value}' not found or lookup factory is invalid or missing`;
            }
        } else if (isLookupFactory(value)) {
            lookup.value = value.create();
        } else if (isLookup(value)) {
            lookup.value = value;
        } else if (isArray(value)) {
            const options = (<[]>value).map(o => isArray(o) ? {value: o[0], text: o[1], icon: o[2]} : o) as LookupOption<TValue>[];
            lookup.value = {
                options: _ => options,
                resolve: (v: any) => options.filter(option => valueOf(option) == v).map(textOf)[0] as any,
            }
        } else if (isObject(value)) {
            const options = toArray(value);
            lookup.value = {
                options: _ => options,
                resolve: (key) => (<any>value)[key] as any
            }
        } else {
            lookupError.value = `Lookup unknown or missing`;
            lookup.value = undefined;
        }
    }, {immediate: true})


    const searchOption: ComputedRef<SearchOption> = computed(() => createSearchOption(filter.value));
    const searchOptionStr: ComputedRef<SearchOptionStr> = computed(() => createSearchOptionStr(filter.value));
    const replaceRegExp: ComputedRef<RegExp> = computed(() => createReplaceRegExp(searchOption.value));

    const filterFunc: Ref<FilterFunction<TValue> | undefined> = ref();
    const optionsSource: Ref<LookupOption<TValue>[]> = ref([]);

    const isSearchMatching = (option: LookupOption<TValue>): boolean => {
        const text = getTranslatedText(option);
        return isStrMatching(text, searchOption.value);
    };

    const getLookupOptions = () =>
        lookup.value && lookup.value.options
            ? lookup.value.options(entity.value)
            : null;

    const options: ComputedRef<LookupOption<TValue>[]> = computed(() =>
        filterFunc.value
            ? optionsSource.value
            : searchOption.value.include.length > 0
                ? optionsSource.value.filter(isSearchMatching)
                : optionsSource.value);

    const optionsDict: ComputedRef<Record<any, LookupOption<TValue>>> = computed(() =>
        options.value.reduce((dict, option) => {
            dict[<any>option.value] = option;
            return dict;
        }, <Record<any, LookupOption<TValue>>>{}));

    const setOptionsSource = (value: any, max: number = 0) => {
        const array = ensureArray(value);
        lookupCount.value = array.length
        lookupMax.value = max;
        optionsSource.value = max > 0
            ? array.slice(0, max).map(createLookupOption)
            : array.map(createLookupOption);
    };

    const clearOptionsSource = () =>
        setOptionsSource([]);

    const onResult = (result: any, max: number = 0) => {
        if (isPromise(result)) {
            busyCount.value++;
            result
                .then(data => setOptionsSource(data, max))
                .catch(_ => {
                })
                .finally(() => busyCount.value--);
        } else {
            setOptionsSource(result, max);
        }
    }

    watch(getLookupOptions, result => {
        //if (dataSource.value === "OPERATOR") {
        //    console.log("watch lookupOptions");
        //}
        if (result) {
            if (isFunction(result)) {
                filterFunc.value = result;
                clearOptionsSource();
            } else {
                filterFunc.value = undefined;
                onResult(result);
            }
        } else {
            filterFunc.value = undefined;
            clearOptionsSource();
        }
    }, {immediate: true});

    watch([filterFunc, searchOptionStr], ([func, {include, exclude}]) => {
        //if (dataSource.value === "OPERATOR") {
        //    console.log("watch filterFunc", !!func);
        //}
        if (func) {
            const max = 6;
            const result = func(0, max, include, exclude);
            onResult(result, max);
        }
    }, {immediate: true});

    const optionHasIcon = (option: LookupOption<TValue>) => option && isString(option.icon);
    const optionHasFlagIcon = (option: LookupOption<TValue>) => option && isString(option.country);

    const optionsHaveIcons = computed(() => !!defaultIcon.value || options.value.some(optionHasIcon));
    const optionsHaveFlagIcons = computed(() => options.value.some(optionHasFlagIcon));

    const optionsAreBooleans = computed(() =>
        options.value.length >= 2 &&
        options.value.length <= 3 &&
        isBoolean(options.value[0].value) &&
        isBoolean(options.value[1].value)
    );

    const setFilter = (value: string) =>
        filter.value = value;

    const clearFilter = () =>
        filter.value = "";

    const ensureValue = (value: TValue) =>
        optionsDict.value[<any>value]?.value || value;

    const resolveValue = (value: TValue) => {
        if (lookup.value && isFunction(lookup.value.resolve)) {
            const resolveResult = lookup.value.resolve(value, entity.value);
            return isPromise(resolveResult)
                ? new Promise((resolve, reject) => {
                    resolveResult
                        .then(option => resolve(createLookupOption(option)))
                        .catch(reject);
                })
                : resolveResult;
        }
        return undefined;
    }

    const getOption = function (value: TValue) {
        //console.log(dataSource.value, value);
        const option = optionsDict.value[<any>value];
        return isUndefinedOrNull(option) ? resolveValue(value) : option;
    };

    // const getDisplay = (value: TValue) => {
    //     const option = getOption(value);
    //     if (option !== undefined) {
    //         return option;
    //     }
    //     if (lookup.value) {
    //         const option = lookup.value.resolve(value, entity.value);
    //         return createLookupOption(<any>option);
    //     }
    //     return undefined;
    // };

    const getIcon = (option: LookupOption<TValue>) => {
        const icon = optionHasIcon(option)
            ? option.icon
            : undefined;
        return tryResolveIconAlias(icon || defaultIcon.value || "far");
    };

    const getFlagIcon = (option: LookupOption<TValue>) =>
        option && isString(option.country)
            ? `flag-icon-${option.country}`.toLowerCase()
            : undefined;

    //const getDeletedIcon = (option: LookupOption<TValue>) => {
    //    const icon = option && option.display && isLookupDisplay(option.display) && option.display.icon
    //        ? option.display.icon
    //        : undefined;
    //    return icon || defaultIcon.value || "far";
    //    return "far"
    //};

    const getTranslatedText = (option?: LookupOption<TValue>) =>
        ensureTextTranslated(option?.text || "") || (option?.value as any)?.toString() || "";

    const getFilteredText = (option: LookupOption<TValue>) =>
        markStr(getTranslatedText(option), replaceRegExp.value);

    const getColoredIconStyle = (option: LookupOption<TValue>) =>
        option && optionsHaveColors.value
            ? stringToHslStyles(option.colorDeterminationText || option.text)
            : undefined;

    const getTextFromOptionValue = (value: TValue) => {
        if (isUndefinedOrNull(value)) {
            return ""
        }
        const option = getOption(value);
        return isPromise(option)
            ? new Promise<string>((resolve, reject) => {
                option
                    .then(text => resolve(getTranslatedText(text)))
                    .catch(reject);
                
            })
            : getTranslatedText(option);
    };


    return <LookupUse>{
        lookup,
        filter,
        lookupError,
        defaultIcon,
        lookupCount,
        lookupMax,
        isBusy,

        options,
        optionsDict,

        optionsHaveIcons,
        optionsHaveFlagIcons,
        optionsHaveColors,
        optionsAreBooleans,

        setFilter,
        clearFilter,
        ensureValue,

        getOption,
        //getDisplay,
        getIcon,
        getFlagIcon,
        getTranslatedText,
        getFilteredText,
        getColoredIconStyle,
        getTextFromOptionValue

    }

};