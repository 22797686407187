﻿import type {LookupOption, LookupDisplay} from "../implementations/lookup/useLookup";

import {computed, ref, onMounted} from "vue";
import {isUndefinedOrNull} from "./inspect";

const AUTO = "auto";
const SMOOTH = "smooth";

const ARROW_UP_KEY = 'ArrowUp'
const ARROW_DOWN_KEY = 'ArrowDown'

const PAGE_UP_KEY = "PageUp";
const PAGE_DOWN_KEY = "PageDown";

const ENTER_KEY = "Enter";
const SPACE_KEY = "Space";
const HOME_KEY = "Home";
const END_KEY = "End";

const getStartChar = (str: any) => str?.[0]?.toUpperCase();

export const useDropdownMenu = () => {

    const element = ref<HTMLElement>();

    const querySelector = <E extends Element = HTMLElement>(selectors: string): E | undefined =>
        element.value?.querySelector<E>(selectors) || undefined;

    const querySelectorAll = <E extends Element = HTMLElement>(selectors: string): NodeListOf<E> | undefined =>
        element.value?.querySelectorAll<E>(selectors);

    const getItems = () => Array.from(querySelectorAll(".dropdown-item") || []);

    const scrollTo = (itemElement: HTMLElement | undefined) => {
        const containerElement = element.value;
        if (containerElement && itemElement) {
            itemElement.focus({preventScroll: true});
            containerElement.scrollTo({
                behavior: AUTO,
                top: itemElement.offsetTop - (containerElement.clientHeight - itemElement.clientHeight) / 2
            });
        }
    };

    const toFirst = () => {
        const nextItem = querySelector(".dropdown-item:first-child");
        nextItem && nextItem.focus();
    }

    const toLast = () => {
        const nextItem = querySelector(".dropdown-item:last-child");
        nextItem && nextItem.focus();
    }

    const pageUp = () => {
        const container = element.value;
        if (container) {
            const currentItem = document.activeElement as HTMLElement;
            const nextItem = getItems()
                .filter(item => item.offsetTop > currentItem.offsetTop + currentItem.clientHeight - container.clientHeight)[0];
            if (nextItem) {
                nextItem.focus({preventScroll: true});
                container.scrollTo({
                    behavior: SMOOTH,
                    top: nextItem.offsetTop
                });
            }
        }
    }

    const pageDown = () => {
        const container = element.value;
        if (container) {
            const currentItem = document.activeElement as HTMLElement;
            const nextItem = getItems()
                .filter(item => item.offsetTop + currentItem.clientHeight < currentItem.offsetTop + container.clientHeight)
                .reverse()[0];
            if (nextItem) {
                nextItem.focus({preventScroll: true});
                container.scrollTo({
                    behavior: SMOOTH,
                    top: nextItem.offsetTop + nextItem.clientHeight - container.clientHeight
                });
            }
        }
    }

    const toValue = (value: any) => {
        if (isUndefinedOrNull(value)) {
            toFirst();
            return;
        }
        const nextItem = querySelector(`.dropdown-item[data-value='${value}']`);
        scrollTo(nextItem);
    };

    const scrollToStartChar = (char: string) => {
        if (char) {
            const all = querySelectorAll(`.dropdown-item[data-start='${char}']`);
            if (all?.length) {
                const items = Array.from(all);
                const currentItem = items.filter(i => i === document.activeElement)[0];
                const currentIndex = items.indexOf(currentItem);
                const nextItem = currentIndex < items.length - 1
                    ? items[currentIndex + 1]
                    : items[0];
                scrollTo(nextItem);
                return true;
            }
        }
        return false;
    };

    const attrs: Record<string, any> = computed(() => ({
        style: {
            // todo: query height and paddings from css, make 7 as parameter
            "max-height": `${8 + 7 * 29 + 8}px`,
            "overflow-y": "auto", "overscroll-behavior": "contain"
        },
        onKeydown: (e: KeyboardEvent) => {
            console.log("onKeydown DropdownMenu", e);
            switch (e.key) {

                case ARROW_UP_KEY:
                    console.log("^^^");
                    break;

                case HOME_KEY:
                    e.preventDefault();
                    toFirst();
                    break;

                case END_KEY:
                    e.preventDefault();
                    toLast();
                    break;

                case PAGE_UP_KEY:
                    e.preventDefault();
                    pageUp();
                    break;

                case PAGE_DOWN_KEY:
                    e.preventDefault();
                    pageDown();
                    break;

                case SPACE_KEY:
                case ENTER_KEY:
                    break;

                default:
                    if (e.key?.length === 1) {
                        const startChar = getStartChar(e.key);
                        if (scrollToStartChar(startChar)) {
                            e.preventDefault();
                        }
                    }
                    break;
            }
        }
    }));



    const dropdownMenuAttrs: Record<string, any> = computed(() => ({
        class: [
            "dropdown-menu",
            "dropdown-menu-end"
        ],
        ...attrs.value
    }));

    const getDropdownItemAttrs = (option: LookupOption, active?: boolean, focus?: boolean, truncate?: boolean) => ({
        "type": "button",
        "data-value": option.value,
        "data-start": getStartChar(option.text) || " ",
        "class": {
            "dropdown-item": true,
            "text-truncate": truncate,
            "active": active,
            "focus": focus
        }
    });


    return {
        element,
        attrs,
        dropdownMenuAttrs,
        getDropdownItemAttrs,

        toValue,
        toFirst,
        toLast, 
        pageDown,
        pageUp

    }

}
