import {useMateApi} from "./useMateApi";
import {AccessToken} from "./types";

type FileFormat = "csv" | "pdf" | "xml" | "txt" | "xlsx" | "zip";

type Options = {
    filename?: string;
    newTab?: boolean;
    format?: FileFormat
}

const mimeDict: Record<FileFormat, string> = {
    "csv": "text/csv",
    "pdf": "application/pdf",
    "xml": "application/xml",
    "txt": "text/plain",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "zip": "application/zip"
}

export const getAcceptHeader = (format: FileFormat | undefined) => ({
    "Accept": format
        ? mimeDict[format]
        : <any>undefined
})

export const isString = (value: any): value is string => typeof value === "string";


export const useDownloadHelper = (accessToken: AccessToken) => {

    const {authorizationHeader, acceptLanguageHeader, baseUrl: baseURL, axiosInstance, endpoint} = useMateApi(accessToken);

    const download = (url: string, options?: Options): Promise<void> =>
        axiosInstance
            .get<Blob>(url, {
                //baseURL,
                responseType: "blob",
                headers: {
                    ...authorizationHeader(),
                    ...acceptLanguageHeader(),
                    ...getAcceptHeader(options?.format)
                }
            })
            .then(response =>
                response.data.text().then(buffer => {
                    const content = response.data;
                    if (isString(content) && content.startsWith("<Fault")) {
                        const xml = new DOMParser().parseFromString(content, "application/xml");
                        const message = xml?.documentElement?.getElementsByTagName("Reason")[0]?.getElementsByTagName("Text")[0]?.textContent;
                        throw {message: message || "Failed"};
                    }

                    let fallbackFilename = "";
                    
                    const contentDisposition = response.headers["content-disposition"];
                    if (contentDisposition) {
                        const res = new RegExp(/filename="(.*)"/g).exec(contentDisposition);
                        if (res) {
                            fallbackFilename = res[1];
                        }
                    }

                    const a = document.createElement("a");
                    a.target = options?.newTab ? "_blank" : "_self";
                    a.download = options?.filename || fallbackFilename;
                    a.href = URL.createObjectURL(content);
                    a.click();
                }))

    return {
        download
    }

}