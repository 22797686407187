import type {Ref} from "vue";
import {computed, unref} from "vue";
import {MessageType} from "./useToasts";

//const refreshIcon = "far fa-rotate-right";
const refreshIcon = "fad fa-sync-alt fa-rotate-90";
//const refreshIcon = "fad fa-rotate-right";


export type IconAlias =
    "add" |
    "busy" |
    "check" |
    "copy" |
    "clipboard" |
    "cut" |
    "edit" |
    "delete" |
    "download" |
    "duplicate" |
    "failed" |
    "file-csv" |
    "file-excel" |
    "file-pdf" |
    "file-zip" |
    "grip" |
    "info" |
    "more" |
    "more-v" |
    "paste" |
    "print" |
    "redo" |
    "refresh" |
    "refresh-busy" |
    "replace" |
    "save" |
    "search" |
    "share" |
    "undo" |
    "user" |
    "warning";

const dict: Record<IconAlias, string> = {
    "add": "far fa-plus",
    "busy": "fad fa-spinner-third fa-spin as-busy",
    "check": "far fa-check-circle text-primary",
    "copy": "fad fa-copy",
    "clipboard": "fad fa-clipboard",
    "cut": "fad fa-scissors",
    "edit": "fad fa-edit",
    "delete": "fad fa-trash-can",
    "download": "fad fa-download",
    "duplicate": "fad fa-copy",
    "failed": "fas fa-octagon-exclamation text-danger",
    "file-csv": "fad fa-file-csv",
    "file-excel": "fad fa-file-excel",
    "file-pdf": "fad fa-file-pdf",
    "file-zip": "fad fa-file-zipper",
    "grip": "fas fa-grip-vertical",
    "info": "fal fa-circle-info",
    "more": "fal fa-circle-ellipsis",
    "more-v": "fal fa-circle-ellipsis-vertical",
    "paste": "fad fa-paste",
    "print": "fad fa-print",
    "redo": "fad fa-redo-alt",
    //"refresh": "far fa-rotate-right", 
    "refresh": refreshIcon,
    "refresh-busy": refreshIcon + " fa-spin",
    "replace": "fad fa-search-dollar",
    "save": "fad fa-save",
    "search": "fad fa-search",
    "share": "fad fa-share-alt",
    "undo": "fad fa-undo-alt",
    "user": "fad fa-user",
    "warning": "fal fa-triangle-exclamation"
}



const busyMessageIconDict: Record<MessageType, string> = {
    "info": "far fa-circle-info after-busy-shake",
    "success": "far fa-circle-check after-busy",
    "warning": "far fa-triangle-exclamation after-busy-shake",
    "error": "far fa-circle-xmark after-busy-shake",
    "system-error": "far fa-circle-xmark after-busy-shake"
}

const toastMessageIconDict: Record<MessageType, string> = {
    "info": "fal fa-circle-info",
    "success": "fal fa-circle-check",
    "warning": "fal fa-triangle-exclamation",
    "error": "fal fa-circle-xmark fa-flash",
    "system-error": "fal fa-circle-xmark fa-flash",
}

export const resolveIconAlias = (alias: IconAlias): string =>
    dict[alias] || alias;

export const tryResolveIconAlias = (iconOrAlias: IconAlias|string): string =>
    dict[<IconAlias>iconOrAlias] || iconOrAlias;

export const getBusyMessageIcon = (messageType: MessageType) =>
    busyMessageIconDict[messageType];

export const getToastMessageIcon = (messageType: MessageType) =>
    toastMessageIconDict[messageType];

export const useIconAlias = (iconOrAlias: Ref<any> | any) => {

    const icon = computed(() => resolveIconAlias(<any>unref(iconOrAlias)));

    return {
        icon
    }
}